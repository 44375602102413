import Swal from 'sweetalert2'

window.swal = (title, text, icon, confirmButtonText = null, denyButtonText = null, cancelButtonText = null, timer = null, cb = null) => {
  return Swal.fire({
    showDenyButton: denyButtonText !== null,
    showCancelButton: cancelButtonText !== null,
    title,
    html: text,
    icon,
    confirmButtonText,
    denyButtonText,
    cancelButtonText,
    timer,
    didOpen: () => {
      if (cb) {
        cb()
      }
    }
  })
}
